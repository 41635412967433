<!--
 * @Description: 各品类联系人
 * @Author: wangying
 * @Date: 2021-10-09 09:43:06
-->
<template>
  <div>
    <div>
      <div class="title">
        <ListTitle :headerNmae="headerNmae" :headerNmaeCh="headerNmaeCh"></ListTitle>
      </div>
      <p class="hr"></p>
      <div>
        <!-- <div class="thesyek">
          <div class="thesye theht">供应链企业环境合规化</div>
          <div class="fx theht thesyeb">
            <div class="thebox1">牵头机构:公众环境研究中心(IPE)</div>
            <div class="thebox2">联系人:丁杉杉</div>
            <div class="thebox3">联系电话:010-6738230-8942</div>
          </div>
        </div>
        <div class="thesyek">
          <div class="thesye theht ">供应链企业环境合规化</div>
          <div class="fx theht thesyeb">
            <div class="thebox1">牵头机构:公众环境</div>
            <div class="thebox2">联系人:丁杉杉</div>
            <div class="thebox3">联系电话:010-6738230-8942</div>
          </div>
        </div> -->
        <div>
          <div style="line-height: 2; margin-bottom: 30px;" v-html="category.content"> </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTitle from "@/components/reuse/listTitle";
import { articleListByPage, articleDetail } from '@/api'

export default {
  data() {
    return {
      headerNmaeCh: '<p style="color: #57802b;">Contact person of each category</p>',
      headerNmae:
        '<p style="color: #57802b; letter-spacing:2px; font-size: 35px;">各品类联系人</p>',
      pages: {
        page: 1,
        limit: 999
      },
      category: {}
    }
  },
  mounted() {
    this.hub.$emit('scrollToz')
    this.hub.$emit('tatleNames')
    this.hub.$emit('theCover', this.$route.meta.flagNam)
    this.articleListByPage()
  },
  methods: {
    articleListByPage() {
      // console.log(this.dateY,' =====____');
      const params = {
        articleId: 'WZNR201610311949190009',
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleDetail(params).then((res) => {
        console.log(res.data);
        this.category = res.data
        // this.list = [res.data]
        // this.content = 
      })
    },
  },
  components: {
    ListTitle
  }
}
</script>
<style lang="less" scoped>
.fx {
  display: flex;
}

// .htejcn {
//     justify-content: center;
//     margin: 50px 0 20px 0;
// }
.title {
  margin-top: 30px;
}

.hr {
  margin-top: 34px;
  margin-right: 22px;
  margin-bottom: 10px;
  border-top: 2px solid #57802b;
}

.thesyek {
  margin: 20px 20px 0px 0;
  border: 2px solid #92AB23;
  border-bottom: none;
}

.theht {
  height: 50px;
}

.thesye {
  width: 450px;
  line-height: 50px;
  padding-left: 30px;
  color: #467128;
  // background: #92ab23;
  font-size: 20px;
  font-weight: bold;
}

.thesyeb {
  margin-bottom: 20px;
  justify-content: space-between;
  padding-left: 30px;
  font-size: 16px;
  line-height: 50px;
  border: 2px solid #92AB23;
  border-top: 1px solid #92AB23;
  border-left: none;
  border-right: none;
  font-weight: 600;
  // color: #fff;
  // background-color: #467128;
}

.thebox1 {
  width: 380px;
  border-right: 1px solid #92AB23;
}

.thebox2 {
  flex: 1;
  text-align: left;
  text-align: center;
  border-right: 1px solid #92AB23;

}

.thebox3 {
  width: 380px;
  padding-left: 40px;
}
</style>